export default class Custom {
  
  constructor() {  
    $('.header__topbar--search').on('click', 'a', function(e) {
      $('.search-bar').fadeIn()
      e.preventDefault()
      
      $(document).mouseup(function (e){
        var container = $('.search-bar form')
        
        if (!container.is(e.target)
        && container.has(e.target).length === 0)
        {
          $('.search-bar').fadeOut()
        }
      })
    })
    
    if ($('.js-image-slider')) {
      $('.js-image-slider').slick ({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        prevArrow: $('.image-single .text-inner').find('.slick-prev'),
        nextArrow: $('.image-single .text-inner').find('.slick-next'),
        fade: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              asNavFor: '.text-wrapper'
            }
          }
        ]
      });
    }
    
    if ($('.js-text-slider')) {
      $('.js-text-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        fade: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              asNavFor: '.image-slider'
            }
          }
        ]
      });
    }

    // if ($('.js-resources-carousel')) {
    //   $('.js-resources-carousel').slick({
    //     dots: false,
    //     arrows: wrapper.data('arrows'),
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: wrapper.data('items'),
    //     autoplay: wrapper.data('autoplay'),
    //     autoplaySpeed: 4000,
    //     responsive: [
    //       {
    //         breakpoint: 992,
    //         settings: {
    //           slidesToShow: wrapper.data('items-sm')
    //         }
    //       },
    //       {
    //         breakpoint: 767,
    //         settings: {
    //           slidesToShow: wrapper.data('items-xs')
    //         }
    //       }
    //     ]
    //   })
    // }

    // if ($('.js-carousel')) {
    //   $('.js-carousel').slick({
    //     dots: false,
    //     arrows: wrapper.data('arrows'),
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: wrapper.data('items'),
    //     autoplay: wrapper.data('autoplay'),
    //     autoplaySpeed: 4000,
    //     responsive: [
    //       {
    //         breakpoint: 992,
    //         settings: {
    //           slidesToShow: wrapper.data('items-sm')
    //         }
    //       },
    //       {
    //         breakpoint: 767,
    //         settings: {
    //           slidesToShow: wrapper.data('items-xs')
    //         }
    //       }
    //     ]
    //   })
    // }
    
    if ($('.js-carousel').length) {
      $('.js-carousel').each(function (key, element) {
        var wrapper = $(element)
        wrapper.slick ({
          dots: false,
          arrows: wrapper.data('arrows'),
          infinite: true,
          speed: 500,
          slidesToShow: wrapper.data('items'),
          autoplay: wrapper.data('autoplay'),
          autoplaySpeed: 4000,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: wrapper.data('items-sm')
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: wrapper.data('items-xs')
              }
            }
          ]
        })
      })
    }

    // font size switcher
    $('.footer__font-size').on('click', 'a', function(e){
      e.preventDefault();
      $('.footer__font-size a.size').removeClass('active');
      $(this).addClass('active');
      if($(this).hasClass('font-size-1')) {
        $('body').removeClass('text-size2 text-size3')
      }else if($(this).hasClass('font-size-2')) {
        $('body').removeClass('text-size3').addClass('text-size2')
      }else if($(this).hasClass('font-size-3')) {
        $('body').removeClass('text-size2').addClass('text-size3')
      }
    })
    
    $(document).on('click', '.menu-hamburger', function(e) {
      if ($('.overlay').hasClass('open')) {
        $('.overlay').removeClass('open')
      } else {
        $('.overlay').addClass('open')
      }
      e.preventDefault()
    })
    
    $(document).mouseup(function (e){
      var container = $('.overlay-container');
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $('.overlay').removeClass('open')
      }
    })
    
    $(document).on('click', '.close-container', function(e) {
      if($('.overlay').hasClass('open')) {
        $('.overlay').removeClass('open')
      } else {
        $('.overlay').addClass('open')
      }
      e.preventDefault()
    })
    
    // top/mobile nav translate
    $('.translate-list a').click(function(e) {
      e.preventDefault();
      window.location = $(this).attr('href');
      location.reload();
    });
    
    // Banner Trigger if Not Closed
    if (!localStorage.bannerClosed) {
      $('.privacy-banner').css('display', 'inherit');
    } else {
      $('.privacy-banner').css('display', 'none');
    }
    $('.privacy-banner button').click(function() {
      $('.privacy-banner').css('display', 'none');
      localStorage.bannerClosed = 'true';
    });
    $('.banner-accept').click(function() {
      $('.privacy-banner').css('display', 'none');
      localStorage.bannerClosed = 'true';
    });
    if (navigator.userAgent.match(/Opera|OPR\//)) {
      $('.privacy-banner').css('display', 'inherit');
    }

    // Get report textarea content for better formatting
    $('.report-content table textarea').each(function() {
      var text = $(this).val();
      var textmod = text.replace(/\r?\n/g, "<br>\n");
      $(this).parent().html('<div class="border border-silver px-4 py-3 mb-5 text-left report-text">'+ textmod +'</div>');
    });

    if ($('[data-background]')) {
      console.log($('[data-background]'));
      $('[data-background]').each(function() {
        var attr, that;
        that = $(this);
        attr = that.attr('data-background');
        that.css('background-image', 'url(' + attr + ')');
      });
    }
    

    if ($('.header-slider')) {
      $('.header-slider').slick({
        arrows: false,
        dots: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        appendDots: '#dots-container .container'
      });
      $('.header-slider--prev').each(function() {
        $(this).on('click', function() {
          $('.header-slider').slick('slickPrev');
        });
      });
      $('.header-slider--next').each(function() {
        $(this).on('click', function() {
          $('.header-slider').slick('slickNext');
        });
      });
      /* Accessibility Labels for Sliders */
      $('.header-slider .slick-track').attr('aria-label', 'Homepage Slider');
    }

    // function for email links
    function mt(n,d,s,b) {
      var hrefLink = "mailto:" + n + "@" + d;
      if(s){
        hrefLink += connector + "subject=" + encodeURI(s);
        connector = "&";
      }
      if(b){
        hrefLink += connector + "body=" + encodeURI(b);
      }
      location.href = hrefLink;
    }
  }
}